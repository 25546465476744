<template>
  <div class="main-view-padding has-header">
    <div class="header main" v-header-position-lock>
      <div class="align-left"></div>
      <div class="align-middle">
        <headerbuttonstack :state="headerButtons" />
      </div>
      <div class="align-right">
        <headerbuttonstack :state="sideButtons" />
      </div>
    </div>

    <div v-if="loading">
      <i class="fas fa-circle-notch fa-spin"></i> Bitte warten<br />
      Die ÖGK Clearing Meldungen werden gerade geladen<br />
      <div
        style="width: 100%; clear: both; border-bottom: 1px solid #ccc; margin-top: 25px"
      ></div>
    </div>
    <div v-if="!loading" class ="tablecontainer scrollbar-container">
      <datatablesimple :state="dataTableSimpleState" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "eldaClearingOverview",
  data() {
    return {
      loading: true,

      insurer: [
        {
          value: "03",
          label: "Sozialversicherungsanstalt der Selbständigen",
        },
        {
          value: "05",
          label: "BVAEB",
        },
        {
          value: "08",
          label: "Allgemeine Unfallversicherungsanstalt",
        },
        {
          value: "11",
          label: "ÖGK Wien",
        },
        {
          value: "12",
          label: "ÖGK Niederösterreich",
        },
        {
          value: "13",
          label: "ÖGK Burgenland",
        },
        {
          value: "14",
          label: "ÖGK Oberösterreich",
        },
        {
          value: "15",
          label: "ÖGK Steiermark",
        },
        {
          value: "16",
          label: "ÖGK Kärnten",
        },
        {
          value: "17",
          label: "ÖGK Salzburg",
        },
        {
          value: "18",
          label: "ÖGK Tirol",
        },
        {
          value: "19",
          label: "ÖGK Vorarlberg",
        },
        {
          value: "24",
          label: "Betriebskrankenkasse Mondi",
        },
        {
          value: "25",
          label: "Betriebskrankenkasse voestalpine Bahnsysteme",
        },
        {
          value: "26",
          label: "Betriebskrankenkasse Zeltweg",
        },
        {
          value: "28",
          label: "Betriebskrankenkasse Kapfenberg",
        },
        {
          value: "1A",
          label: "KFA der Bediensteten der Stadt Wien",
        },
        {
          value: "4A",
          label: "KFA der Landeshauptstadt Linz",
        },
        {
          value: "4B",
          label: "Kranken- und Unfallfürsorgeträger der OÖ Gemeinden",
        },
        {
          value: "4C",
          label: "Kranken- und Unfallfürsorgeträger für OÖ Landesbedienstete",
        },
        {
          value: "4D",
          label: "OÖ Lehrer-Kranken- und Unfallfürsorge",
        },
        {
          value: "4E",
          label: "KFA der Beamten der Stadt Steyr",
        },
        {
          value: "4F",
          label: "KFA der Stadt Wels",
        },
        {
          value: "5A",
          label: "KFA der Landeshauptstadt Graz",
        },
        {
          value: "6A",
          label: "KFA der Stadt Villach",
        },
        {
          value: "7A",
          label: "KFA der MagistratsbeamtInnen der Stadt Salzburg",
        },
        {
          value: "7C",
          label: "KF d. pragmatisierten Bediensteten der Stadt Hallein",
        },
        {
          value: "94",
          label: "Bundesrechenzentrum GmbH",
        },
        {
          value: "99",
          label: "Dachverband der österreichischen Sozialversicherungen",
        },
        {
          value: "ST",
          label: "Statistik Austria",
        },
        {
          value: "MO",
          label: "Tabak – Monopolverwaltung GmbH",
        },
        {
          value: "A0",
          label: "Bundesarbeitskammer",
        },
      ],

      eldaEmployerInsurer: [],
      clearingMessages: [],
      filterDropdownOptions: [],

    };
  },
  created: function () {
    //console.log("CREATED");
  },
  mounted: function () {
    this.refresh();
  },
  computed: {
    ...mapGetters([
      "companyId",
      "isSuperUser",
    ]),
    headerButtons() {
      let view = this;

      let state = {
        buttons: [],
      };
      state.buttons.push({
        type: "block",
        icon: "fa-refresh",
        tooltip: 'Daten neu laden',
        click: ($event) => {view.refresh();},

      });
      return state;
    },
    sideButtons() {
      let view = this;

      let state= {
        buttons: []
      };
      state.buttons.push({
        type: "block border-r",
        icon: "fa-eye",
        tooltip: 'Filter Beitragskontonummer',
        click: ($event) => {view.OpenFilterDropdown($event);},

      });

      return state;
    },

    dataTableSimpleState() {
      let view = this;

      let data = Object.values(view.clearingMessages)
        .filter(cm => {
          let val = false;
          view.filterDropdownOptions.forEach(item => {
            if(item.insurer !== undefined) {
              if (item.insurer.insurer === cm.meldung_info.traegercode && item.insurer.contribution_accountnumber === cm.meldung_info.beitragskontonummer) {
                val = item.insurer.show;
              }
            }
          });
          return val;
        })
        .map((e) => {
          return Object.assign({}, e)
        });

      data.sort((a,b) => {
        if (a.meldung_info.bereitstellungsdatum > b.meldung_info.bereitstellungsdatum) {
          return -1;
        } else {
          return 1;
        }
      });

      data.forEach(d => {
        d.subEntries = [
          {
            componentOverride: 'eldaClearingDetails',
            componentStateBuilders: [(data) => {
              return {
                clearingMessage: d,
                componentType: 'eldaClearingDetails',
                customEvents: {
                  Update: () => {
                  }
                }
              }
            }]
          }
        ]
      });

      let state = {};

      let columns = {};
      columns.refw = 'Referenzwert der Meldung';
      columns.satzartBez = 'Meldungsart';
      columns.date = 'Datum';
      columns.versicherungstraeger = 'Versicherungsträger';
      columns.beitragskontonummer = 'Beitragskontonummer';
      columns.name = 'Name';
      columns.zustellungsgrund = 'Zustellungsgrund';
      columns.dringlichkeit = 'Dringlichkeit';
      columns.actions = ' ';

      state = this.$helpers.GetDataTableStateFromKeyLabelObject(columns, data);

      state.headerMap.refw.sort = false;
      state.headerMap.refw.componentStateBuilders = [(data, entry) => {
        return {
          componentType: 'labelc',
          state: {
            text: data.meldung_info.referenzwert,
          },
          styleLabel: {
            display: 'flex', alignItems: 'center', justifyContent: 'center', background: '', height: 'calc(100% - 10px)'
          },
        }
      }];

      state.headerMap.satzartBez.sort = false;
      state.headerMap.satzartBez.componentStateBuilders = [(data, entry) => {
        return {
          componentType: 'labelc',
          state: {
            text: data.meldung_info.satzart + ' - ' + data.meldung_info.satzartBez,
          },
          styleLabel: {
            display: 'flex', alignItems: 'center', justifyContent: 'center', background: '', height: 'calc(100% - 10px)'
          },
        }
      }];

      state.headerMap.date.sort = false;
      state.headerMap.date.componentStateBuilders = [(data, entry) => {
        return {
          componentType: 'labelc',
          state: {
            text: this.formatDateForUser(data.meldung_info.bereitstellungsdatum),
          },
          styleLabel: {
            display: 'flex', alignItems: 'center', justifyContent: 'center', background: '', height: 'calc(100% - 10px)'
          },
        }
      }];

      state.headerMap.versicherungstraeger.sort = false;
      state.headerMap.versicherungstraeger.componentStateBuilders = [(data, entry) => {
        return {
          componentType: 'labelc',
          state: {
            text: view.getInsurerLabelByKey(data.meldung_info.traegercode),
          },
          styleLabel: {
            display: 'flex', alignItems: 'center', justifyContent: 'center', background: '', height: 'calc(100% - 10px)'
          },
        }
      }];

      state.headerMap.beitragskontonummer.sort = false;
      state.headerMap.beitragskontonummer.componentStateBuilders = [(data, entry) => {
        return {
          componentType: 'labelc',
          state: {
            text: data.meldung_info.beitragskontonummer,
          },
          styleLabel: {
            display: 'flex', alignItems: 'center', justifyContent: 'center', background: '', height: 'calc(100% - 10px)'
          },
        }
      }];

      state.headerMap.name.sort = false;
      state.headerMap.name.componentStateBuilders = [(data, entry) => {
        return {
          componentType: 'labelc',
          state: {
            text: data.meldung_info.familienname.toUpperCase() + " " + data.meldung_info.vorname,
          },
          styleLabel: {
            display: 'flex', alignItems: 'center', justifyContent: 'center', background: '', height: 'calc(100% - 10px)'
          },
        }
      }];

      state.headerMap.zustellungsgrund.sort = false;
      state.headerMap.zustellungsgrund.componentStateBuilders = [(data, entry) => {
        let reason = '';
        switch (data.zustellungsgrund) {
          case "M": reason = 'Dialogfall gemeldet'; break;
          case "U": reason = 'Dialogfall urgiert'; break;
          case "O": reason = 'Dialogfall obsolet'; break;
        }
        if (data.testkennzeichen === "J") {
          reason = reason + " [TEST]";
        }
        return {
          componentType: 'labelc',
          state: {
            text: reason,
          },
          styleLabel: {
            display: 'flex', alignItems: 'center', justifyContent: 'center', background: '', height: 'calc(100% - 10px)'
          },
        }
      }];

      state.headerMap.dringlichkeit.sort = false;
      state.headerMap.dringlichkeit.componentStateBuilders = [(data, entry) => {
        let urgency = '';
        switch (data.meldung_info.dringlichkeit) {
          case "D": urgency = 'dringend'; break;
          case "K": urgency = 'Kontrollfall'; break;
          case "N": urgency = 'nicht dringend'; break;
        }
        return {
          componentType: 'labelc',
          state: {
            text: urgency,
          },
          styleLabel: {
            display: 'flex', alignItems: 'center', justifyContent: 'center', background: '', height: 'calc(100% - 10px)'
          },
        }
      }];

      // Actions
      state.headerMap.actions.stylesEntry = {'justify-content': 'left'};
      state.headerMap.actions.componentStateBuilders = [];

      let entries = Object.entries(state.headerMap);

      let mainColumnWidth = 25;
      let mainColumns = [""];

      let actionColumns = ["actions"];
      let actionColumnWidth = 3;
      for (let x in entries) {

        if (mainColumns.includes(entries[x][0])) {
          entries[x][1].width = mainColumnWidth + '%';
        } else if (actionColumns.includes(entries[x][0])) {
          entries[x][1].width = actionColumnWidth + '%';
        }
        //entries[x][1].width = ((100 / (entries.length + 1)) * (x === (''+(entries.length-1)) ? 0.5 : 1)) + '%';
        //console.log(entries[x][1].width)
        entries[x][1].key = entries[x][0];

      }
      state.headerStyles = {position: "sticky", top: 0, zIndex: 10, background: 'var(--sub-menu)', wordBreak: 'break-word'};
      state.heightSubRow = 'fit-content';
      state.rowStyles = {background: 'var(--ml)'};
      state.rowStylesCollapsed = {background: 'var(--contrast-1)'};
      state.sumRowStyles = {background: 'var(--sub-menu)'}
      state.alwaysUnfold = this.alwaysUnfold;
      return state;
    },
  },
  watch: {
    companyId: function (val, oldVal) {
      this.refresh();
    },
    selectedStores: function (val, oldVal) {
      console.log("Selected stores changed in EldaClearingOverview.vue");
      console.log(oldVal);
      console.log(val);
    },
  },
  // methods that implement data logic.
  methods: {
    refresh: function (force = false) {
      let view = this;
      view.loading = true;
      view.initArrays();
      view.loadEldaEmployerSettings()
        .then((employerSettings) => {
          view.loadFilterDropdown();
          view.eldaEmployerInsurer.forEach(insurer => {
            view.loadClearingMessages(insurer)
              .then(clearingMessages => {
                view.loading = false;
              });
          });
        })
        .catch(function (error) {
          view.eldaEmployerInsurer = [];
          console.log(error);
          view.loading = false;
        });
    },

    initArrays() {
      let view = this;
      view.eldaEmployerInsurer = [];
      view.clearingMessages = [];
      view.filterDropdownOptions = [];
    },
    pad(num, size) {
      num = num.toString();
      while (num.length < size) num = "0" + num;
      return num;
    },
    formatDateForUser(dateString) {
      let view = this;
      let date = new Date(dateString);
      return view.pad(date.getDate(),2) + '.' + view.pad(date.getMonth()+1,2) + '.' + date.getFullYear() + ' '
        + view.pad(date.getHours(),2) + ':' + view.pad(date.getMinutes(),2) + ':' + view.pad(date.getSeconds(),2);
    },
    getInsurerLabelByKey: function(key) {
      let label = '';
      this.insurer.forEach(function (item, index) {
        if (item.value === key) {
          label = item.label;
        }
      });
      return label;
    },

    loadClearingMessages: function (insurer) {
      let view = this;
      let url = "/api/sec/elda/clearing";
      const params = {
        companyId: this.$store.state.companyId,
        bknr: insurer.contribution_accountnumber,
        vstr: insurer.insurer,
      };
      return this.axios({
        method: "get",
        headers: {
          AUTHORIZATION: "Bearer " + this.$store.state.jwt,
        },
        url: url,
        params,
      })
        .then((response) => {
          let data = response.data;

          console.log(data);

          if (data !== undefined) {
            data.forEach(item => {
              view.clearingMessages.push(item);
            });
          } else {
            view.clearingMessages = [];
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    loadEldaEmployerSettings: function () {
      let view = this;
      let url = "/api/sec/elda/settings/employer";
      const params = {
        companyId: this.$store.state.companyId,
      };
      return this.axios({
        method: "get",
        headers: {
          AUTHORIZATION: "Bearer " + this.$store.state.jwt,
        },
        url: url,
        params,
      })
        .then((response) => {
          let data = response.data;

          console.log(data);

          if (data !== undefined) {
            view.eldaEmployerInsurer = JSON.parse(JSON.stringify(data.insurer));
          } else {
            view.eldaEmployerInsurer = [];
          }
        })
    },

    loadFilterDropdown(){
      var view = this;
      this.filterDropdownOptions = [];
      Object.values(view.eldaEmployerInsurer).forEach(function(insurer){
        insurer.show = true;
        var option = {label: insurer.contribution_accountnumber + ' - ' + view.getInsurerLabelByKey(insurer.insurer), toggled: true, insurer: insurer};
        option.color = "#555555";

        view.filterDropdownOptions.push(option);
      });
      this.filterDropdownOptions.push({label: "Alle", toggled: true, all: true, color: "#888888"})
    },
    OpenFilterDropdown($event){
      var view = this;
      var target = $event.currentTarget;


      this.$helpers.OpenPopup({
        elRelative: target,
        type: 'list',
        componentState: {
          entries: view.filterDropdownOptions,
        },
        remainAfterSelect: true,
        pointerDirection: 0,
        iconsLeft: true,
        selectCallback: function($event, item, dropdown){
          item.toggled = !item.toggled;
          if(item.insurer) item.insurer.show = item.toggled;
          if(item.all) {
            view.filterDropdownOptions.forEach(option => {
              if(option.insurer){
                option.toggled = item.toggled;
                option.insurer.show = item.toggled;
              }
            })
          }
          view.filterDropdownOptions = view.filterDropdownOptions.slice();
        },
      });
    },
  },
};
</script>

<!-- Add "scoped" attriemployeete to limit CSS to this component only -->
<style scoped>

  .tablecontainer{
    padding-left: 10px;
    padding-right: 10px;
  }

  .has-header > .scrollbar-container{
    position: initial;
    height: calc(100% - var(--header-height)) !important;
    top: var(--header-height);
    overflow-x: hidden;
  }

  .header.top{
    margin: 10px 0;
  }
  .data-table{
    top: 10px;
    position: relative;
  }

  .header.date{
    display: inline-flex;
    justify-items: center;
    align-items: center;
    position: absolute;
    left: 20px;
  }

  .header.date .label{
    color: var(--contrast-4);
    font-family: DistrictProLight;
    font-weight: 200;
    margin-top: -3px;
  }

  .header.date .label.bold{
    font-family: DistrictProBold;
    font-weight: 800;
    margin-right: 5px;
    margin-top: -3px;
  }

  .header.date .fa-calendar{
    margin: 0 10px;
    font-size: 20px;
    margin-top: -3px;
  }
  .header.date .fa-angle-left{
    margin: 0 10px;
    font-size: 25px;
    color: var(--contrast-4);
  }
  .indicator-view{
    padding: 30px;
    padding-bottom: 50px;
  }
</style>
